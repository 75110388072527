import Router from 'next/router'
import { useEffect } from 'react'

const FourOhFour = () => {
  useEffect(() => {
    Router.push('/')
  }, [])
  return null
}

export default FourOhFour
